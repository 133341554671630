import React from "react"
import { Package } from "../Classes/Package";
import Layout from "./Layout/layout"
import ConsultantSubscribe from "../Views/Website/Subscribe/Consultant/Consultant";

const ConsultantPage: React.FC = (props: any) => {
  const pack: Package = props.pageContext.Package;

  return (
    <Layout>
      {pack ? <ConsultantSubscribe Package={pack} /> : <></>}
    </Layout>
  );
};

export default ConsultantPage;