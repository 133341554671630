// extracted by mini-css-extract-plugin
export var AccountMessage = "Consultant-module--AccountMessage--19a8e";
export var Active = "Consultant-module--Active--91ad2";
export var Advice = "Consultant-module--Advice--53d2b";
export var AdviceBlock = "Consultant-module--AdviceBlock--04223";
export var Button = "Consultant-module--Button--a4a4f";
export var Checkbox = "Consultant-module--Checkbox--aab7d";
export var Empty = "Consultant-module--Empty--22163";
export var Error = "Consultant-module--Error--c29ec";
export var Half = "Consultant-module--Half--d6c85";
export var Highlight = "Consultant-module--Highlight--a48f9";
export var Input = "Consultant-module--Input--e8b81";
export var Loading = "Consultant-module--Loading--beddd";
export var LoadingWheel = "Consultant-module--LoadingWheel--a4009";
export var Message = "Consultant-module--Message--cf546";
export var Pay = "Consultant-module--Pay--81a67";
export var PayBlock = "Consultant-module--PayBlock--922e0";
export var Prices = "Consultant-module--Prices--1e98d";
export var PricesBlock = "Consultant-module--PricesBlock--2a2d9";
export var Selected = "Consultant-module--Selected--88cad";
export var Silver = "Consultant-module--Silver--0e418";
export var Subscribe = "Consultant-module--Subscribe--bac94";
export var SubscribeInner = "Consultant-module--SubscribeInner--52c72";
export var SubscribeMain = "Consultant-module--SubscribeMain--dad8f";
export var SubscribeSide = "Consultant-module--SubscribeSide--5b269";
export var Tabs = "Consultant-module--Tabs--3ce33";
export var Title = "Consultant-module--Title--dfeef";
export var Total = "Consultant-module--Total--5a09a";
export var User = "Consultant-module--User--348cf";