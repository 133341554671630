import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AdviceLine } from '../../../../Classes/AdviceLine';
import { Package } from '../../../../Classes/Package';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import { Loading } from '../../../../components/Loading/Loading';
import LoginForm from '../../../../components/Login/Login';
import RegisterForm from '../../../../components/Register/Register';
import UserProfile from '../../../../components/User/User';
import { UserContext } from '../../../../Context/UserContext';
import { API_AdviceLines, API_BaseURL, API_SquareCreateSubscription } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import * as styles from './Consultant.module.scss';
import { User } from '../../../../Classes/User';
import { parseJwt, DecryptValue, Decrypt } from '../../../../Services/Crypto';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

interface Props {
  Package: Package;
}

type SubPackage = {
  PackageID: number;
  PackageLength: number;
  AdviceID: number;
  AdviceLength: number;
  BankID: string;
}

const ConsultantSubscribe: React.FC<Props> = ({ Package }) => {
  const [profile, setProfile] = useState<User>({} as User);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [adviceLine, setAdviceLine] = useState<AdviceLine | undefined>(undefined);
  const [isAccount, setIsAccount] = useState<boolean>(true);
  const [messages, setMessages] = useState<string[]>([] as string[]);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { User, setUser } = useContext(UserContext);

  useMemo(() => {
    if (typeof window !== 'undefined') {
      let token = localStorage.getItem("User");
      if (token) {
        let jwt = parseJwt(Decrypt(token));
        setProfile({
          ID: parseInt(jwt.nameid),
          isAdmin: jwt.role.includes("ADMIN") ? jwt.role.split(":")[1] === "true" : false,
          isEmployee: jwt.role.includes("EMPLOYEE"),
          isConsultant: jwt.role.includes("CONSULTANT"),
          Email: jwt.email,
          Name: jwt.unique_name
        } as User)
      }
    }
  }, [])

  useEffect(() => {
    if (User)
      setProfile(User);
  }, [User])

  useEffect(() => {
    document.title = "PrimeHR :: HR Consultant Package";
    GetData();
  }, [])

  useEffect(() => {
    let mess: string[] = [] as string[];
    if (User.ID) {
      if (User.isConsultant) {
        if (User.Subscription) {
          if ((User.Subscription.isGold || User.Subscription.isSilver)) {
            mess.push("You already have a membership");
          }
        }
      } else {
        mess.push("You are not signed up as a HR Consultant");
      }
    }
    setMessages(mess);
  }, [User]);

  const GetData = async () => {
    setLoading(true);

    await Fetch(`${API_AdviceLines}`)
      .then((Response: AdviceLine[]) => {
        setAdviceLine(Response.find(x => x.ID === 1));
      })

    setLoading(false);
  }

  const AdjustNumber = (Value: number) => {
    return (Math.round(Value * 100) / 100).toFixed(2)
  }

  const SubscribeViaSquare = async (token: any) => {
    const data: SubPackage = {
      PackageID: 4,
      PackageLength: 1,
      AdviceID: 1,
      AdviceLength: 1,
      BankID: token
    }

    setSubmitting(true);
    await Fetch(`${API_SquareCreateSubscription}`, data)
      .then((Response: User) => {
        if (Response.ID) {
          setUser(Response);
          setSuccess(true);
        }
      }).catch(() => {
        return;
      })
    setSubmitting(false);
  }

  // const RedirectFlow = async () => {
  //   setLoading(true);

  //   const data: SubPackage = {
  //     PackageID: 4,
  //     PackageLength: 1,
  //     AdviceID: 1,
  //     AdviceLength: 1,
  //     SortCode: bankID && bank ? "" : sortCode,
  //     AccountNumber: bankID && bank ? "" : accountNumber,
  //     BankID: bankID && bank ? bank["id"] : undefined,
  //     AccountName: bankID && bank ? "" : accountName,
  //     Discount: ""
  //   }

  //   if (bankID || (sortCode && accountNumber))
  //     await Fetch(`${API_GCAddCustomer}`, data)
  //       .then((Response) => {
  //         if (Response.RedirectFlow) {
  //           window.location.href = Response.RedirectFlow.URL;
  //         } else {
  //           setUser(Response.User);
  //           setSuccess(Response.Success);
  //         }
  //       })
  //   else
  //     setMessages(["Please provide an Account Number and Sort Code or use an existing account"]);

  //   setLoading(false);
  // }

  return (<>
    <Banner />

    <Breadcrumb Trail={[
      { To: `/Subscribe?PackageID=0&PackageLength=0&AdviceID=0&AdviceLength=0`, Text: "Subscribe" },
      { To: "", Text: "Consultant" }
    ]} />

    {!success ? <div className={styles.Subscribe}>
      {loading ? <Loading /> : <></>}

      <div className={styles.SubscribeInner}>
        <div className={styles.SubscribeMain}>
          {profile.ID ? <div className={styles.User}>
            <h2>Continue As:</h2>
            <UserProfile />

            <h1>OR</h1>
          </div> : <></>}

          <div className={styles.Tabs}>
            <button className={isAccount ? styles.Active : ""} onClick={() => { setIsAccount(true); }}>
              I Have An Account
            </button>

            <button className={!isAccount ? styles.Active : ""} onClick={() => { setIsAccount(false); }}>
              I Don't Have An Account
            </button>
          </div>

          {isAccount ? <LoginForm /> : <RegisterForm />}
        </div>
      </div>

      <div className={styles.SubscribeSide}>
        <h1 className={styles.Title}>Purchasing: </h1>

        <div className={`${styles.Advice}`}>
          <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>HR Consultant Package
              </b>
              <span>
                £{AdjustNumber((parseFloat(Package.Cost) / 120) * 100)}
              </span>
            </h2>
          </div>

          <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>HR Consultant Advice
              </b>
              <span>
                £{AdjustNumber(((adviceLine ? parseFloat(adviceLine.MonthPrice) : 0) / 120) * 100)}
              </span>
            </h2>
          </div>

          <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>Total</b>
              <span>
              </span>
              <span className={styles.Total}>
                <small>£{AdjustNumber((29.99 / 120) * 100)}</small>
                £1.00
              </span>
            </h2>
          </div>

          <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>Incl. VAT (20%)
                <small>
                  £{(adviceLine ? parseFloat(adviceLine.MonthPrice) : 0) + (Package.ID ? (parseFloat(Package.Cost)) : 0)} After the First Month Reoccuring Every Month (cancel anytime)
                </small>
              </b>
              <span className={styles.Total}>
                <small>£29.99</small>
                £1.20
              </span>
            </h2>
          </div>
        </div>

        <div className={`${styles.AdviceBlock} ${submitting ? styles.Loading : ""}`}>
          {profile.ID ? messages.length ? <>
            {
              messages.map(message => {
                return <div className={styles.Error}>
                  <p>{message}</p>
                </div>
              })
            }
          </> : <>
            {submitting ? <Loading /> :
              <PaymentForm
                applicationId={API_BaseURL === "/" ? "sq0idp-XJnj35l4gr1O-c6wSC2LQQ" : "sandbox-sq0idb-58Lfiex_YCefaJxIiskU1w"}
                cardTokenizeResponseReceived={(token, buyer) => {
                  console.info({ token, buyer });
                  SubscribeViaSquare(token.token);
                }}
                createVerificationDetails={() => ({
                  amount: '1.00',
                  /* collected from the buyer */
                  billingContact: {
                    addressLines: [''],
                    familyName: User.Surname,
                    givenName: User.Forename,
                    countryCode: 'GB',
                    city: '',
                  },
                  currencyCode: 'GBP',
                  intent: 'CHARGE',
                })}
                locationId={API_BaseURL === "/" ? "LPMPP77K32X39" : "LZYXW9FJKDCTE"}
              >
                <CreditCard />
              </PaymentForm>
            }
          </> : <div className={styles.AccountMessage}>
            Please Sign In or Create An Account to Continue
          </div>}
        </div>
      </div>
    </div> : <div className={styles.Message}>
      <h1>You Have Successfully Subscribed To The HR Consultant Package!</h1>
      <i>
        <FontAwesomeIcon icon={faCheckCircle} />
      </i>
      <Link to={"/Consultant/Portal/Clients"} className={styles.Button}>
        Start Using Now
      </Link>
    </div>}
  </>
  );
};

export default ConsultantSubscribe;
